import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—August 2012" />
	<NewsHeader />
    <h2>August 2012</h2>




	<h3 className="blogdate">
	Saturday, August 18, 2012
	</h3>

	<h4 className="blogitemtitle">
	Back to it in Wellington: radio performance, concert
	</h4>
	   <div className="blogitembody">
	   <p>
	Hola Amigos!
	</p>
	<p>
	After a long hiatus, <i>JR &amp; The Rec Devs</i> are reunited for a
	show (and hopefully more!) in September! Actually, two shows if you
	count the live radio performance we're doing to promote the concert. The
	rehearsals are on and we'd love to have a nice, warm crowd of supportive
	friends, interested peers, and intrigued newcomers... please spread the
	word!
	</p>
	<p>
	Also, I'm still running the promotion of &quot;buy one get one free&quot; as
	outlined in my previous news post:
	</p>
	<ul>
	<li>
	<Link to="/news/201201/">Bandcamp
	promotion</Link>&#160;
	</li>
	</ul>
	<p>
	The details for the radio performance and the concert in Wellington are:
	</p>
	<ol>
	<li>
	<b>live on RadioActive FM</b>, between 2:00 and 4:00pm, Sunday, <b>Sep
	9, 2012</b><br /><i>Shady's Americana Show</i><br />88.6 FM on local
	dials, and easy live streaming at website; timezones:<br />Vancouver:
	Sat, Sep 8, 7:00 to 9:00pm<br />Calgary: Sat, Sep 8, 8:00 to 10:00pm<br />Montreal:
	Sat, Sep 8, 10:00pm to midnight<br />Buenos Aires: Sat, Sep 8, 11:00pm
	to 1:00am<br /><a href="http://www.radioactive.co.nz/">Website for
	streaming/listening</a>&#160;
	</li>
	<li>
	<b>live at Meow</b>, 7:30pm, Thursday, <b>Sep 13, 2012</b><br />with <i>special
	guest</i> songsmith <i>Miles Calder</i><br />koha/donation at the door<br /><a href="http://www.facebook.com/events/101749913306200/">Facebook
	event for sharing</a>&#160;
	</li>
	</ol>
	<p>
	Happily yours,
	</p>
	<p>
	<i>Jesse Rivest and The Recent Developments</i>
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 16:12 +1200</p>
	   </div>






	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
